import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GlobalState } from './context/global';
import MainPage from './pages';
import LoginPage from './pages/login';

type AppProps = {
  children?: React.ReactNode;
};

const App: React.FC<AppProps> = (props) => {
  return (
    <GlobalState>
      <Router>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/*' element={<MainPage />} />
        </Routes>
      </Router>
    </GlobalState>
  );
};

export default App;
