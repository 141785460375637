import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { MessageBar, MessageBarType, PrimaryButton, Spinner, Stack, Text, TextField } from '@fluentui/react';

import LogoImage from './../../assets/logo/logo-square.png';
import AuthPageBackgroundImage from './../../assets/backgrounds/auth-page.jpg';
import styles from './styles.module.scss';
import eventServices from '../../services/events';
import { EVENT_ID } from '../../props/keys';
import { useGlobalState } from '../../context/global';
import urls from './../../utils/urls.json';

type LoginPageTypes = {
  children?: React.ReactNode;
};

const LoginPage: React.FC<LoginPageTypes> = (props) => {
  const [eventId, setEventId] = React.useState<string | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const { dispatch } = useGlobalState();
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (eventId !== undefined && eventId.trim() !== '') {
      setSubmitting(true);
      setErrorMessage(undefined);
      try {
        const event = await eventServices.get(eventId);
        localStorage.setItem(EVENT_ID, eventId);
        dispatch({ type: 'EVENT', payload: event });
        navigate(urls.home.index);
      } catch (error:any) {
        setSubmitting(false);
        console.log(error);
        setErrorMessage("Please check event code");
      }
    }
  };

  return (
    <Stack className={styles.authPage}>
      <Stack className={styles.container} horizontal>
        <Stack className={styles.leftContainer}>
          <Stack
            className={styles.background}
            styles={{ root: { background: `url(${AuthPageBackgroundImage})` } }}
          />
          <Stack
            verticalAlign={'center'}
            horizontalAlign={'center'}
            className={styles.content}
            tokens={{ childrenGap: 20 }}
          >
            {errorMessage ? <MessageBar messageBarType={MessageBarType.error}>
              <Text variant='small'>{errorMessage}</Text>
            </MessageBar> : null}
            <Stack styles={{ root: { width: '100%' } }} tokens={{ childrenGap: 20 }}>
              <Stack.Item>
                <TextField
                  label='Event Id'
                  value={eventId}
                  onChange={(e, v) => setEventId(v)}
                  disabled={submitting}
                />
              </Stack.Item>
              {!submitting ? (
                <PrimaryButton
                  disabled={eventId === undefined || eventId.trim() === ''}
                  text='MASUK'
                  onClick={onSubmit}
                />
              ) : null}
              {submitting ? <Spinner /> : null}
            </Stack>
          </Stack>
        </Stack>
        <Stack className={styles.rightContainer}>
          <Stack verticalAlign={'center'} horizontalAlign={'center'} className={styles.content}>
            <Stack>
              <img src={LogoImage} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LoginPage;
