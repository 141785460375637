import React, { useState } from 'react';
import { Stack, Text } from '@fluentui/react';

import styles from './styles.module.scss';
import { EventCodeType, EventType } from '../../../../props/events';
import GeneralConfig from '../../../../config';
import eventCodeServices from '../../../../services/events/code';
import { EVENT_CODES_LIST } from '../../../../props/keys';

type WelcomePageProps = {
  children?: React.ReactNode;
  event: EventType;
  onCodeScanned(code: EventCodeType): void;
};

const WelcomePage: React.FC<WelcomePageProps> = (props) => {
  const event = props.event;
  const [barcode, setBarcode] = useState<string>("");
  const [lockBarcode, setLockBarcode] = useState<boolean>(false);

  window.onkeypress = function(evt) {
    if (!lockBarcode) {
      let code = evt.keyCode ? evt.keyCode : evt.which;
      setBarcode(barcode+String.fromCharCode(code));
      
      if ((evt.key || "").toLowerCase()  === "enter") {
        console.log(barcode);
        setLockBarcode(true);
        _onBarcodeScanned(barcode);
      }
    }
  }

  const _onBarcodeScanned = async (barcode: string) => {
    try {
      const codes = JSON.parse(localStorage.getItem(EVENT_CODES_LIST) || "[]") as EventCodeType[];
      const code = codes.find((c) => c.name === barcode);
      //const code = await eventCodeServices.get(event.id, barcode);
      if (code) {
        props.onCodeScanned(code);
      } else {
        throw new Error("Code not registered");
      }
    } catch(e) {
      setLockBarcode(false);
      setBarcode("");
    }
  }

  return (
    <Stack
      className={styles.container}
      styles={{
        root: { backgroundImage: `url(${GeneralConfig.assetsHostname}/${event.welcomeBackground?.url})` },
      }}
      tokens={{ childrenGap: 100 }}
    >
      <Stack className={styles.textContainer}>
        <Text className={`${styles.text} ${styles.title}`} style={{color: event.welcomeTitleColor || 'unset'}}>{event.welcomeTitle}</Text>
        <Text className={`${styles.text} ${styles.subTitle}`} style={{color: event.welcomeDescriptionColor || 'unset'}}>{event.welcomeDescription}</Text>
      </Stack>
      <button className={styles.button} onClick={() => {}}>
        Scan Below
      </button>
    </Stack>
  );
};

export default WelcomePage;
