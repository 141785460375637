import { TAction, TState } from './types';

export function reducer(state: TState, action: TAction) {
  switch (action.type) {
    case 'EVENT':
      return {
        ...state,
        event: action.payload,
      };
    default:
      throw new Error('Unexpected action');
  }
}
