import React from 'react';
import { Stack, Text } from '@fluentui/react';

import styles from './styles.module.scss';
import { EventType } from '../../../../props/events';
import GeneralConfig from '../../../../config';

type RedeemedPageProps = {
  children?: React.ReactNode;
  event: EventType;
};

const RedeemedPage: React.FC<RedeemedPageProps> = (props) => {
  const { event } = props;
  return (
    <Stack
      className={styles.container}
      styles={{
        root: { backgroundImage: `url(${GeneralConfig.assetsHostname}/${event.thankyouBackground?.url})` },
      }}
      tokens={{ childrenGap: 100 }}
    >
      <Stack className={styles.textContainer} tokens={{childrenGap: 20}}>
        <Text className={`${styles.text} ${styles.title}`} style={{color: event.reedemedTitleColor || 'unset'}}>{event.reedemedTitle}</Text>
        <Text className={`${styles.text} ${styles.subTitle}`} style={{color: event.reedemedDescriptionColor || 'unset'}}>{event.reedemedDescription}</Text>
      </Stack>
    </Stack>
  );
};

export default RedeemedPage;
