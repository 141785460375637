import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import urls from './../../utils/urls.json';
import FlavoursPage from './components/flavours';
import ThankYouPage from './components/thankyou';
import WelcomePage from './components/welcome';

import logo from './../assets/logo/logo-square.png';
import { EventCodeType, EventFlavourType, EventTransactionProps, EventType } from '../../props/events';
import { EVENT_CODES_LIST, EVENT_ID, FLAVOURS_LIST, TRANSACTIONS_LIST } from '../../props/keys';
import eventServices from '../../services/events';
import GeneralConfig from '../../config';
import ThankyouPage from './components/thankyou';
import ErrorPage from './components/error';
import RedeemedPage from './components/reedemed';
import eventFlavourServices from '../../services/events/flavour';
import eventTransactionServices from '../../services/events/transaction';
import eventCodeServices from '../../services/events/code';

type EventPageProps = {
    event: EventType;
};

type TStep = 'welcome' | 'thankyou' | 'flavours' | 'error' | 'reedemed';

const EventPage: React.FC<EventPageProps> = (props) => {
    const [event, setEvent] = useState<EventType>(props.event);
    const [code, setCode] = useState<EventCodeType | undefined>();
    const [step, setStep] = useState<TStep>('flavours');
    const [loaded, setLoaded] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        getEventId();
    }, []);

    const getEventId = () => {
        const eventId = localStorage.getItem(EVENT_ID);
        if (eventId) {
            getEvent(eventId);
        } else {
            navigate(urls.login.index);
        }
    };

    const getEvent = async (eventId: string) => {
        try {
            const event = await eventServices.get(eventId);
            const flavours = await eventFlavourServices.retrieve(eventId);
            let transactions = await eventTransactionServices.retrieve(eventId);
            transactions = transactions.filter((tr) => {
                return flavours.findIndex((fl) => fl.id === tr.flavour.id) > -1;
            });
            const codes = await eventCodeServices.retrieve(eventId);

            localStorage.setItem(EVENT_ID, eventId);
            localStorage.setItem(FLAVOURS_LIST, JSON.stringify(flavours));
            localStorage.setItem(TRANSACTIONS_LIST, JSON.stringify(transactions));
            localStorage.setItem(EVENT_CODES_LIST, JSON.stringify(codes));

            setEvent(event);
            setLoaded(true);
        } catch (error) {
            navigate(urls.login.index);
        }
    };

    const _onCheckIsCodeRegistered = (code: EventCodeType) => {
        const transactions = JSON.parse(localStorage.getItem(TRANSACTIONS_LIST) || "[]") as EventTransactionProps[];
        return transactions.findIndex((tr) => tr.code === code.id && tr.status === 'completed') > -1;
    }

    const _onCodeScanned = (code: EventCodeType) => {
        const isRedeemed = _onCheckIsCodeRegistered(code);

        setCode(code);
        if (isRedeemed || Number(code.isRedeemed) === 1) {
            setStep('reedemed');
            setTimeout(() => {
                setCode(undefined);
                setStep('welcome');
            }, 4000);
        } else {
            setStep('flavours');
        }
    }

    const _onFlavourSelected = () => {
        setStep('thankyou');
        setTimeout(() => {
            setCode(undefined);
            setStep('welcome');
        }, 4000);
    }

    const _onRejected = () => {
        setStep('error');
        setTimeout(() => {
            setCode(undefined);
            setStep('welcome');
        }, 4000);
    }

    return event ? (
        <Stack className={styles.container}>
            {!loaded ? <Stack>
                <Spinner size={SpinnerSize.large} label={'Preparing ...'} labelPosition={'bottom'} />
            </Stack> : null}
            {event.logos[0] && (
                <img
                    className={styles.logo}
                    src={`${GeneralConfig.assetsHostname}/${event.logos[0].url}`}
                    alt='Logo'
                />
            )}
            {loaded ? <>
                {step === 'welcome' || !code ? <WelcomePage event={event} onCodeScanned={_onCodeScanned} /> : null}
                {step === 'flavours' && code ? <FlavoursPage event={event} code={code} onFlavourSelected={_onFlavourSelected} onRejected={_onRejected} /> : null}
                {step === 'thankyou' ? <ThankyouPage event={event} /> : null}
                {step === 'error' ? <ErrorPage event={event} /> : null}
                {step === 'reedemed' ? <RedeemedPage event={event} /> : null}
            </> : null}
        </Stack>
    ) : (
        <></>
    );
};

export default EventPage;
