import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import React, { useEffect } from 'react';

import styles from './styles.module.scss';

import product from './../../../../assets/product.png';
import { EventCodeType, EventFlavourType, EventTransactionProps, EventType } from '../../../../props/events';
import GeneralService from '../../../../services/general';
import eventTransactionServices from '../../../../services/events/transaction';
import { useGlobalState } from '../../../../context/global';
import { useNavigate } from 'react-router-dom';
import GeneralConfig from '../../../../config';
import eventFlavourServices from '../../../../services/events/flavour';
import { EVENT_CODES_LIST, FLAVOURS_LIST, TRANSACTIONS_LIST } from '../../../../props/keys';

type FlavoursPageProps = {
  children?: React.ReactNode;
  event: EventType;
  code: EventCodeType;
  onFlavourSelected(): void;
  onRejected(): void;
};

const FlavoursPage: React.FC<FlavoursPageProps> = (props) => {
  const { event, code } = props;
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [flavours, setFlavours] = React.useState<EventFlavourType[]>([]);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { dispatch } = useGlobalState();
  const navigate = useNavigate();

  const selectFlavour = async (flavour: EventFlavourType) => {
    setSubmitting(true);
    const data = new FormData();
    data.append('flavourId', flavour.id);
    data.append('quantity', '1');
    data.append('code', code.id);

    try {
      const response = await eventTransactionServices.create(event.id, data);

      const timer = setInterval(async () => {
        const tr = await eventTransactionServices.get(event.id, response.id);
        if (tr.status === 'completed') {
          clearInterval(timer);

          // set flavours stock
          const flavours = JSON.parse(localStorage.getItem(FLAVOURS_LIST) || "[]") as EventFlavourType[];
          const fid = flavours.findIndex((fl) => fl.id === flavour.id);
          flavours[fid].stock = flavours[fid].stock - 1;
          localStorage.setItem(FLAVOURS_LIST, JSON.stringify(flavours));
          console.log(flavours);

          // add transaction
          const transactions = JSON.parse(localStorage.getItem(TRANSACTIONS_LIST) || "[]") as EventTransactionProps[];
          transactions.push(tr);
          localStorage.setItem(TRANSACTIONS_LIST, JSON.stringify(transactions));
          console.log(transactions);

          // update code is redeemed
          const codes = JSON.parse(localStorage.getItem(EVENT_CODES_LIST) || "[]") as EventCodeType[];
          const cid = codes.findIndex((c) => c.name === code.name);
          codes[cid].isRedeemed = 1;
          localStorage.setItem(EVENT_CODES_LIST, JSON.stringify(codes));
          console.log(codes);

          props.onFlavourSelected();
        } else if (tr.status === 'rejected') {
          clearInterval(timer);
          props.onRejected();
        }
      }, 3000);
    } catch (e) {
      props.onRejected();
    }
  };

  const init = async () => {
    const flavours = localStorage.getItem(FLAVOURS_LIST);
    setFlavours(JSON.parse(flavours || "[]"));
    setLoaded(true);
  }

  useEffect(()=> {
    init();
  }, []);

  return (
    <Stack
      className={styles.container}
      styles={{
        root: {
          backgroundImage: event.mainBackground
            ? `url(${GeneralConfig.assetsHostname}/${event.mainBackground?.url})`
            : undefined,
        },
      }}
    >
      <Stack className={styles.flavourContainer}>
        {submitting ? (
          <Stack>
            <Spinner size={SpinnerSize.large} label={'Preparing ...'} labelPosition={'bottom'} />
          </Stack>
        ) : null}
        {!loaded ? (
          <Stack>
            <Spinner size={SpinnerSize.large} label={'Retrieving all flavours ...'} labelPosition={'bottom'} />
          </Stack>
        ) : null}
        {!submitting && loaded &&
          flavours.map((flavour) => (
            <Stack className={`${styles.flavour}`} tokens={{ childrenGap: 10 }}>
              <Stack className={styles.imageContainer}>
                {flavour.stock > 0 ? (
                  <Stack className={styles.button} onClick={() => selectFlavour(flavour)}>
                    <Text className={styles.textBtn}>Click</Text>
                    <Text className={styles.textBtn}>here</Text>
                  </Stack>
                ) : null}
                {flavour.stock < 1 ? (
                  <Stack className={styles.oos}>
                    <Text className={styles.title}>OUT OF STOCK</Text>
                  </Stack>
                ) : null}
                <img
                  height={250}
                  width={250}
                  src={flavour.images[0] ? GeneralService.getFileUrl(flavour.images[0].url) : product}
                  alt='Product'
                />
              </Stack>
              <Stack>
                <Text className={styles.name} style={{ color: flavour.titleColor || 'unset' }}>
                  {flavour.name}
                </Text>
                <Text className={styles.title} style={{ color: flavour.descriptionColor || 'unset' }}>
                  ({flavour.description || ''})
                </Text>
              </Stack>
            </Stack>
          ))}
      </Stack>
      <Text className={styles.text} style={{ color: event.chooseFlavourTitleColor || 'unset' }}>
        {event.chooseFlavourTitle || 'Choose the flavour'}
      </Text>
    </Stack>
  );
};

export default FlavoursPage;
