import { EventCodeType, EventFlavourType, EventTransactionProps, FileType } from '../../props/events';
import Request from './../request';

// import interfaces

const eventTransactionServices = {
  create: async (eventId: string, data: FormData): Promise<any> => {
    try {
      const results = await Request.post(`/api/events/${eventId}/transactions`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (eventId: string, transactionId: string): Promise<any> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/transactions/${transactionId}`, true);
      return results;
    } catch (e) {
      throw e;
    }
  },
  retrieve: async (eventId: string): Promise<EventTransactionProps[]> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/transactions`, true);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default eventTransactionServices;
