import { EventCodeType, EventFlavourType, FileType } from '../../props/events';
import Request from './../request';

// import interfaces

const eventFlavourServices = {
  retrieve: async (eventId: string): Promise<EventFlavourType[]> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/flavours`);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default eventFlavourServices;
