const GeneralConfig = {
  /*homepage: 'http://localhost:3000',
  apiHostname: 'http://localhost:8000',
  hostname: 'http://localhost:3000',
  assetsHostname: 'http://localhost:8000',*/
  homepage: 'https://louregelateria.id',
  apiHostname: 'https://api.louregelateria.id',
  hostname: 'https://louregelateria.id',
  assetsHostname: 'https://api.louregelateria.id',
  dateFormat: 'DD/MM/YYYY',
  googleClientId: '',
  facebookAppId: '',
};

export default GeneralConfig;
