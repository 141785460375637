import { EventCodeType } from '../../props/events';
import Request from './../request';

// import interfaces

const eventCodeServices = {
  retrieve: async (eventId: string): Promise<EventCodeType[]> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/codes`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  create: async (data: FormData, eventId: string): Promise<EventCodeType[]> => {
    try {
      const results = await Request.post(`/api/events/${eventId}/codes`, data, 'multipart/form-data');
      return results;
    } catch (e) {
      throw e;
    }
  },
  update: async (data: FormData, eventId: string, id: string): Promise<EventCodeType[]> => {
    try {
      const results = await Request.post(
        `/api/events/${eventId}/codes/${id}?_method=PUT`,
        data,
        'multipart/form-data'
      );
      return results;
    } catch (e) {
      throw e;
    }
  },
  delete: async (eventId: string, id: string): Promise<EventCodeType[]> => {
    try {
      const results = await Request.delete(`/api/events/${eventId}/codes/${id}`);
      return results;
    } catch (e) {
      throw e;
    }
  },
  get: async (eventId:string, id: string): Promise<EventCodeType> => {
    try {
      const results = await Request.get(`/api/events/${eventId}/codes/${id}`, true);
      return results;
    } catch (e) {
      throw e;
    }
  },
};

export default eventCodeServices;
