import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import urls from './../utils/urls.json';
import FlavoursPage from './event/components/flavours';
import ThankYouPage from './event/components/thankyou';
import WelcomePage from './event/components/welcome';

import logo from './../assets/logo/logo-square.png';
import { EventType } from '../props/events';
import { EVENT_ID } from '../props/keys';
import eventServices from '../services/events';
import GeneralConfig from '../config';
import EventPage from './event';

type MainPageProps = {
  children?: React.ReactNode;
};

const MainPage: React.FC<MainPageProps> = (props) => {
  const [event, setEvent] = useState<EventType>();

  const navigate = useNavigate();

  useEffect(() => {
    getEventId();
  }, []);

  const getEventId = () => {
    const eventId = localStorage.getItem(EVENT_ID);
    if (eventId) {
      getEvent(eventId);
    } else {
      navigate(urls.login.index);
    }
  };

  const getEvent = async (eventId: string) => {
    try {
      const event = await eventServices.get(eventId);
      localStorage.setItem(EVENT_ID, eventId);
      setEvent(event);
    } catch (error) {
      navigate(urls.login.index);
    }
  };
  return event ? <>
      <Routes>
        <Route path={urls.home.index} element={<EventPage event={event} />} />
      </Routes>
    </> : null
};

export default MainPage;
